import React from "react"

import styles from "./article.scss"

export default function Article(props) {
  return (
    <>
      <article>
        <h1 className={"articleTitle"} dangerouslySetInnerHTML={{ __html: props.title }}></h1>
        <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
      </article>
    </>
  )
}
